import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import avatar1 from '../../../../images/Banner.jpeg';
import why_choose from '../../../../images/why_choose_us.jpg';
import work2 from '../../../../images/what_we_can_do.jpg';
import how_work from '../../../../images/how_it_work.jpg';
import partner_icon_1 from '../../../../images/partner_icon_1.png';
import partner_icon_2 from '../../../../images/partner_icon_2.png';
import partner_icon_3 from '../../../../images/partner_icon_3.png';
import choose_icon_6 from '../../../../images/choose_icon_6.png';
import choose_icon_2 from '../../../../images/choose_icon_2.png';
import choose_icon_3 from '../../../../images/choose_icon_3.png';
import choose_icon_1 from '../../../../images/choose_icon_1.png';
import choose_icon_4 from '../../../../images/choose_icon_4.png';
import choose_icon_5 from '../../../../images/choose_icon_5.png';
import icon1 from '../../../../images/music_img.png';
import BuyNow from '../../../Common/components/buynow';
import SimpleTabs from './Tabs';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import DefaultImg from '../../../../images/default_large.png';
import mobileImg1 from "../../../../images/mobile_img_1.png";
import mobileImg2 from "../../../../images/mobile_img_2.png";
import mobileImg3 from "../../../../images/mobile_img_3.png";
import AsianPaints from '../../../../images/partner-logo/Asain-paints-logo.jpg';
import BangaluruLogo from '../../../../images/partner-logo/Bengaluru-moving-logo.jpg';
import BangaluruCityLogo from '../../../../images/partner-logo/Bengaluru-smart-city-logo.jpg';
import EvergreenLogo from '../../../../images/partner-logo/Evergreen-logo.jpg';
import Jayahe from '../../../../images/partner-logo/Jaya-he-logo.jpg';
import NationalMuesum from '../../../../images/partner-logo/National-Museum-logo.jpg';
import Salarjung from '../../../../images/partner-logo/Salarjung-logo.jpg';
import PhonePe from '../../../../images/partner-logo/Phone-pe-logo.jpg';
import Sanskriti from '../../../../images/partner-logo/Sanskriti-Museum-logo.jpg';
import Seniority from '../../../../images/partner-logo/Seniority-logo.jpg';
import StartIndiaLogo from '../../../../images/partner-logo/Start-India-foundation-logo.jpg';
import YLACLogo from '../../../../images/partner-logo/YLAC-logo.jpg';
import TripAdvisor from '../../../../images/partner-logo/TripAdvisor.jpg';
import NGMALogo from '../../../../images/partner-logo/ngma-logo.png';
import SalarJung from '../../../../images/partner-logo/Salarjung-logo.jpg';
import GuruKahan from '../../../../images/partner-logo/gurukahan-logo.png';
import OwlCarousel from 'react-owl-carousel';
import MetaDecorator from '../../../Common/components/metaDecorator';

export default class Partner extends Component {

    constructor() {
        super();
        this.state = {
            mobileScreen: false,
            activeCard: 0,
            tabValue: 0,
            masonaryData: [
                {
                    name: "Jaya He",
                    image: Jayahe
                },
                {
                    name: "Asian Paints",
                    image: AsianPaints
                },
                {
                    name: "Start India foundation",
                    image: StartIndiaLogo
                },
                {
                    name: "Phone Pe",
                    image: PhonePe
                },
                {
                    name: "National Muesum",
                    image: NationalMuesum
                },
                {
                    name: "Sanskriti Museum",
                    image: Sanskriti
                },
                {
                    name: "YLAC",
                    image: YLACLogo
                },
                {
                    name: "Trip Advisor",
                    image: TripAdvisor
                },
                {
                    name: "National Gallaery of Morern Art",
                    image: NGMALogo
                },
                {
                    name: "Salar Jung Audio Guide",
                    image: SalarJung
                },
                {
                    name: "Guru Kahan Art Museum",
                    image: GuruKahan
                }
                /*{
                    name: "Bengaluru moving ",
                    image: BangaluruLogo
                },
                {
                    name: "Bengaluru smart city",
                    image: BangaluruCityLogo
                },
                {
                    name: "Ever green",
                    image: EvergreenLogo
                },*/
                /*{
                    name: "Salarjung",
                    image: Salarjung
                },
                {
                    name: "Seniority",
                    image: Seniority
                },*/
            ],
            meta_info: {
                title: 'Partner',
                meta_description: 'We use the power of modern cloud technology to enhance visitor experiences and tell stories about destinations like Museums and Monuments. We help you kick off your journey of digital transformation with our end-to-end affordable solutions.',
                meta_keyword: 'Desiwalks Tours Pvt Ltd, Desiwalks Tours Private Limited, Desiwalks Tours, NGMA Audio Guide, Salar Jung audio guide, Jaya he Audio Guide, Jaya he Museum, National Museum Audio Guide, National Gallery Of Modern Art, NGMA App Mumbai, NGMA App Delhi, NGMA NGMA App Bangalore, Salar Jung Museum, Salar Jung Audio Guide App, Audio Guide, Audio Guide App, Audio Guides, Museum App, Museum Audio Guide,saas application, saas product, saas app, saas tech, audio tech app, cloud technology, cloud-based app, cloud tech, software as a service product, museum saas product, saas museum app, museum tech, tech in the museum, gallery app, tech saas, saas, museum saas, saas museum, museum product, saas app, what is saas, saas meaning, museum tech saas app,Monument, Street Art, Heritage Walk, Culture Destination Walk, Smart City Tour'
            }
        }
    }

    componentDidMount() {
        this.setState({
            mobileScreen: window.innerWidth < 767 ? true : false
        }, () => {
            // console.log(this.state)
        })
    }

    changeCard = (id) => {
        this.setState({
            activeCard: id
        })
    }

    resetCard = () => {
        this.setState({
            activeCard: 0
        })
    }

    // const classes = useStyles();
    // const [value, setValue] = React.useState(0);

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
    };

    route = (url) => {
        this.props.history.push(url);
    }

    responsive = {
        0: {
            items: 1,
            margin: 10,
        },
        400: {
            items: 1,
            margin: 10,
        },
        600: {
            items: 2,
            margin: 10,
        },
        1000: {
            items: 3,
        },
        1400: {
            items: 4
        },
    };

    render() {
        return (
            <Fragment>
                <MetaDecorator meta_info={this.state.meta_info} />
                {/* Html goes here */}
                <HeaderComponent active={"home"} />
                <BuyNow />
                {/* banner section starts here */}
                <div className="banner_section inner_banner partner_banner" style={{ backgroundColor: '#F2F2F2' }}>
                    <img src={avatar1} alt="Banner image" />
                    <div className="container">
                        <div className="row">
                            <div className="banner_data">
                                <h2 className="banner_main_title with_sub_title">Build your Museum, Monument <br />or destination Audio Multimedia App today</h2>
                                <p>Solve your visitor experience needs of Technology Content or Marketing <br />with increased flexibility, scalability and affordability</p>
                                <div className="d-inline-block">
                                    <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/get-in-touch/#contactForm"> Get In Touch</a>
                                    <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/request-a-demo/#contactForm"> Request A Demo</a>
                                    {/* <button className="btn btn-sm orange_btn mr-3" onClick={() => this.route('/cities-and-tours/' + 'get-in-touch')}> Get In Touch</button>
                                    <button className="btn btn-sm orange_btn" onClick={() => this.route('/cities-and-tours/' + 'request-a-demo')}> Request A Demo</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* banner section ends here */}

                {/* icons section start here */}
                <section className="page_section three_section">
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-12 col-lg-4 mb-3">
                                <div className="icon_box">
                                    <div className="left_icon">
                                        <img src={partner_icon_1} alt="Icon" width="60px" />
                                    </div>
                                    <div className="right_desc">
                                        <h4 className=""> ESSENTIAL </h4>
                                        <p>Mobile Audio guide App on Android and iOS and Mobile Web App with Cloud hosting and App store subscription. Ready App for your content </p>
                                        <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/i-need-ustomization/#contactForm"> I am interested</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-3">
                                <div className="icon_box">
                                    <div className="left_icon">
                                        <img src={partner_icon_2} alt="Icon" width="60px" />
                                    </div>
                                    <div className="right_desc">
                                        <h4 className=""> COMPLETE </h4>
                                        <p>ESSENTIAL + Audio & Multimedia Visual Content development- Research, Editing and Scripting, Translation. Voice over and Studio recording. Music. Development of visual content- image, video, AR VR. Ready to use App with tours. </p>
                                        <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/i-need-ustomization/#contactForm"> I am interested</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-3">
                                <div className="icon_box">
                                    <div className="left_icon">
                                        <img src={partner_icon_3} alt="Icon" width="60px" />
                                    </div>
                                    <div className="right_desc">
                                        <h4 className=""> SUBSCRIPTION </h4>
                                        <p>Monthly subscription for comprehensive end to end services based on Get Fast, Pay Slow model. May also include Social media content creation support </p>
                                        <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/i-need-ustomization/#contactForm"> I am interested</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* icon toure section ends here */}

                {/* icons section start here */}
                <section className="page_section pb-0 mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="main_title">Our Partners</h2>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-10 text-center walks_section partner-slider">
                                <OwlCarousel className="owl-theme" margin={40} loop={true} nav dots navClass={'owl-prev', 'owl-next'} responsive={this.responsive} >
                                    {
                                        this.state.masonaryData.map((item, index) => {
                                            return (
                                                <div className="item" key={index}>
                                                    <a href="/cities-and-tours"><img src={item.image ? item.image : DefaultImg} alt="Tour Image" style={{ height: '100%' }} /></a>
                                                    <div className="img_text">
                                                        {/* {
                                                            (this.state.activeTrack === item.intro_audio && this.state.playing === true && this.state.loading === false) ? (
                                                                <Fragment>
                                                                    <div className="icon_set_volumn" style={{cursor: 'pointer'}} onClick={this.pauseAudio}>
                                                                        <FontAwesomeIcon icon="pause"></FontAwesomeIcon>
                                                                    </div>
                                                                </Fragment>
                                                            ) : (this.state.activeTrack === item.intro_audio && this.state.playing === true && this.state.loading === true) ? (
                                                                <Fragment>
                                                                    <div style={{cursor: 'pointer', backgroundColor:'black', padding:'7px', borderRadius:'50px', border:'2px solid white'}}>
                                                                        <img src={LoadingImg} style={{backgroundColor:'none', width:'28px'}} />
                                                                    </div>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <div className="icon_set_volumn" style={{cursor: 'pointer'}} onClick={(e) => this.playAudio(e, item.intro_audio)}>
                                                                        <FontAwesomeIcon icon="volume-up"></FontAwesomeIcon>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        }  */}
                                                    </div>
                                                    <p className="white_text">{item.title}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                                {/* <Fragment>
                                    {
                                        this.state.masonaryData ? (
                                            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 4}} >
                                                <Masonry columnsCount={4} gutter="10px">
                                                    {
                                                        this.state.masonaryData && this.state.masonaryData.map((item, i) => {
                                                            let imageHeight = Math.floor(Math.random() * (380 - 220) + 220)
                                                            return (
                                                                <Fragment key={i}>
                                                                    <img src={item.image ? item.image : DefaultImg} height={imageHeight+'px'} className="walks_images" style={{width: "100%",  display: "block", objectFit:'contain', cursor:'pointer'}} alt="" />
                                                                    <p className="image_title_package white_text" style={{textAlign:'left'}}>{item.name}</p>
                                                                </Fragment>
                                                            )
                                                            
                                                        })
                                                    }
                                                </Masonry>
                                            </ResponsiveMasonry>
                                        ) : null
                                    }
                                </Fragment> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* icon toure section ends here */}

                {/* what we can do section starts here */}
                <section className="page_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mt-3 mb-4">
                                <h2 className="main_title with_sub_title">What we can do</h2>
                                <h5>For Museums, Galleries, Monuments, Street Art,<br />HopOn Hop Off buses, Culture Hubs or others</h5>
                            </div>
                        </div>
                        <div className="row align-items-center mt-4">
                            <div className="col-12 col-md-4 text-center text-md-left">
                                <img width={300} src={work2} alt="" className="work_img" />
                            </div>
                            <div className="col-12 col-md-8 text-left">
                                <div className="">
                                    <ul className="list_why">
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Engage museum visitors on their phones through <br />your Multimedia Audio guide app and more</li>
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Create rich storytelling tours in desired themes, languages and duration</li>
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Manage audio and visual content for tours with ease and speed.</li>
                                    </ul>
                                    <p className="pl-10">And achieve all above without adding work for your staff.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-md-4 text-center">
                                <img src={mobileImg1} alt="" className="app_imgs" />
                                <p>Make Your own Android and iOS App</p>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                                <img src={mobileImg2} alt="" className="app_imgs" />
                                <p>Make Your web based App with no need to download</p>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                                <img src={mobileImg3} alt="" className="app_imgs" />
                                <p>Make your tours/tour circuits with Multimedia Audio narration of your points of interest</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* what we can do section ends here */}


                {/* Why choose us section starts here */}
                <section className="page_section pb-0 mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mb-4">
                                <h2 className="main_title with_sub_title">Why choose us</h2>
                                <h5>End to end, Modern and Affordable- All in one</h5>
                            </div>
                        </div>
                        <div className="row align-items-center mt-5">
                            <div className="col-12  col-md-8 order-2 order-md-1">
                                <div className="">
                                    <ul className="list_why">
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> End to end solution- technology, content creation, updates, maintenance and data report services</li>
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Fast, easy to use ,next generation Cloud based SaaS solution</li>
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Affordable price and custom packages</li>
                                    </ul>
                                    <p className="pl-10" id="tabDetails">Above all our solutions are tried, tested and in use successfully...<a href="#tabDetails" className="green_text"><b>know more</b></a></p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 text-center text-md-right order-md-2 order-1">
                                <img width={300} src={why_choose} alt="" className="work_img" />
                            </div>
                        </div>
                        <div className="row" >
                            <div className="tab_section mt-5">
                                <SimpleTabs />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Why choose us section ends here */}


                {/* How it works section starts here */}
                <section className="page_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mb-4 ">
                                <h2 className="main_title with_sub_title">How it works</h2>
                                <h5>You pick and pay</h5>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-4 text-center text-md-left">
                                <img width={210} src={how_work} alt="" className="work_img" />
                            </div>
                            <div className="col-12 col-md-8 text-left">
                                <div className="">
                                    <ul className="list_why">
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Enjoy the whole or piecemeal- Technology + Content + Marketing support/ Technology + Content / Technology only</li>
                                        <li><FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon> Get fast Pay slow or Pay now Get now</li>
                                    </ul>
                                    <p className="pl-10">Packages available for your different needs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center how_it_section mt-3 mb-4">
                            <div className="col-12 col-md-4 mb-4">
                                <img src={choose_icon_6} alt="" width="80px" className=" mb-3" />
                                <p>Access tours with or without <br />app download on their smart phone</p>
                                {/* <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/i-need-ustomization/#contactForm"> I am interested</a> */}
                                {/* <button className="btn btn-sm orange_btn" onClick={() => this.route('/cities-and-tours/'+'i-need-ustomization')}>  I am interested</button> */}
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                                <img src={choose_icon_2} alt="" width="80px" className=" mb-3" />
                                <p>Simple to use, <br />like audio devices in museums, <br />but on visitors phone</p>
                                {/* <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/i-need-ustomization/#contactForm"> I am interested</a> */}
                                {/* <button className="btn btn-sm orange_btn" onClick={() => this.route('/cities-and-tours/'+'i-need-ustomization')}>  I am interested</button> */}
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                                <img src={choose_icon_3} alt="" width="80px" className=" mb-3" />
                                <p>Scan Qr code <br />to download <br />or listen</p>
                                {/*<p>Access tours with or without app download on their smart phone</p>
                                        <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/i-need-ustomization/#contactForm"> I am interested</a>
                                <button className="btn btn-sm orange_btn" onClick={() => this.route('/cities-and-tours/'+'i-need-ustomization')}>  I am interested</button>*/}
                            </div>
                            <div className="col-12 text-center">
                                <a className="btn btn-sm orange_btn mr-3" href="cities-and-tours/i-need-ustomization/#contactForm"> I am interested</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* How it works section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}
