import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../../Common/components/header';
import FooterComponent from '../../../../Common/components/footer';
import OwlCarousel, { render } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import avatar1 from '../../../../../images/city_detail_banner.png';
import StopIcon from '../../../../../images/stop-icon.png';
import musicImg from '../../../../../images/music_img.png';
import Spinner from '../../../../../images/spinner.gif';

import { APIService } from '../../../../../Config/actions/apiAction';
import { Howl, Howler } from 'howler';
import { ToastContainer, toast } from 'react-toastify';
import BuyNow from '../../../../Common/components/buynow';
import MapComponent from './map';
import { TokenService } from '../../../../../Config/actions/tokenAction';
import MetaDecorator from '../../../../Common/components/metaDecorator';

class TourDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tourData: '',
            tour: '',
            saved: false,
            loading: false,
            play: false,
            player: '',
            show: true,
            meta_info: {},
        }
    }

    likeUnlike = () => {
        let fd = new FormData();
        fd.append('product_id', this.state.tour.tour_id);
        fd.append('product_type', '1');
        fd.append('action', this.state.saved ? '1' : '0');
        this.props.dispatch(APIService('POST', '/user/like-unlike', fd)).then((data) => {
            if (data && data.data.status === 'success') {
                this.showToast('success', data.data.message)
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
            else {
                // console.log(data)
            }
        }).catch((err) => {
            // console.log(err)
        })
    }

    componentDidMount() {
        let fd = new FormData();
        fd.append('tour_id', this.props.match.params.id)
        this.props.dispatch(APIService('POST', '/walk/tourdetails', fd)).then((res) => {
            if (res && res.data.status === 'success') {
                this.setState({
                    saved: res.data.data.location_info.is_favourite === '1' ? true : false,
                    tourData: res.data.data,
                    tour: res.data.data.location_info,
                    meta_info: (res.data.data.meta_info != null) ? res.data.data.meta_info : { title: 'HopOn India', meta_description: 'HopOn India', meta_keyword: 'HopOn India' },
                })
            }
            else if (res.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
        }).catch((e) => {
            // console.log(e)
        })

    }

    saveTour = () => {
        if (this.props.user.isAuthenticated) {
            this.setState((prevstate) => {
                return {
                    ...prevstate,
                    saved: !prevstate.saved
                }
            }, () => {
                this.likeUnlike();
            })
        }
        else {
            this.showToast('error', 'Invalid login. Please login again');
        }
    }

    playAudio = (e) => {
        if (this.state.play) {
            this.setState({
                play: false,
                loading: false
            }, () => {
                this.state.player.stop();
            })
        }
        else {
            if (this.state.tour.intro_audio) {
                this.setState({
                    loading: true,
                    play: true
                }, () => {
                    let self = this;
                    const player = new Howl({
                        src: [this.state.tour.intro_audio],
                        autoplay: true,
                        onload: () => {
                            self.setState({
                                loading: false,
                                player: player
                            });
                        },
                        onloaderror: () => {
                            self.setState({
                                loading: false,
                                play: false,
                                player: player
                            }, () => {
                                self.showToast('error', 'Error fetching audio. Please contact administrator');
                            });
                        },
                        onend: () => {
                            this.setState({
                                play: false,
                                loading: false,
                                player: ''
                            })
                        }
                    });
                })
            }
            else {
                this.showToast('error', 'Audio file not found. Please contact administrator');
            }
        }
    }

    showToast = (type, message) => {
        if (this.state.show) {
            this.setState({
                show: false
            }, () => {
                if (type === 'success') {
                    toast.success(message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false
                    })
                }
                else {
                    toast.error(message, {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false
                    })
                }

                setTimeout(() => {
                    this.setState({
                        show: true
                    })
                }, 1000)
            })
        }
    }

    componentWillUnmount() {
        if (this.state.player) {
            this.state.player.stop();
        }
    }

    goBack = () => {
        // let back = this.props.location.state && this.props.location.state.prevPath ? this.props.location.state.prevPath : '';
        // if(back === 'home'){
        //     this.props.history.push('/')
        // }
        // else{
        this.props.history.goBack();
        // }
    }

    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent />
                <BuyNow />
                <ToastContainer />
                {/* banner section starts here */}
                <div className="banner_section inner_banner tour_detail_banner">
                    {
                        (this.state.tour && this.state.tour.banner_img) ? (
                            <img src={(this.state.tour && this.state.tour.banner_img) ? this.state.tour.banner_img : ''} alt="Banner image" />
                        ) : null
                    }
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-border-white inner_page_back" onClick={() => this.goBack()}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* banner section ends here */}
                {/* price section starts here */}
                <section className="page_section price_section_tour">
                    <div className="container">
                        <div className={"row tour_details_desc " + (this.state.tour.tour_price == 0 ? 'freetourBox' : '')}>
                            <div className="col-12 col-md-7">

                            </div>
                            <div className="col-12 col-md-5 d-none d-md-block">
                                <div className="price_box float-md-right">
                                    <div className="price_box_body ">
                                        {
                                            (this.state.tour.tour_price != '0') ? (
                                                <ng-container>
                                                    <h2 className="price_text">{(this.state.tour) ? (this.state.tour.tour_price === '0') ? 'Free' : '₹' + this.state.tour.tour_price : ''}</h2>
                                                    <p>Starting Price per Adult</p>
                                                </ng-container>
                                            ) : null
                                        }
                                        {/* <h2 className="price_text">{(this.state.tour) ? (this.state.tour.tour_price === '0') ? 'Free' : '₹' + this.state.tour.tour_price : ''}</h2>
                                        <p>Starting Price per Adult</p> */}
                                        <div className="d-inline-block purchase_opt">
                                            <FontAwesomeIcon icon="heart" onClick={this.saveTour} className="like_option" style={this.state.saved ? { color: 'red', border: '1px solid silver' } : { color: 'grey', border: '1px solid silver' }}></FontAwesomeIcon>
                                            {
                                                (this.state.tour.tour_price === '0') ? (
                                                    <button className="btn btn-sm orange_btn text-uppercase" onClick={() => this.props.history.push(`/tour-detail/${this.state.tour.tour_id}/free`)}>Free</button>
                                                ) :
                                                    (this.state.tour) ? (this.state.tour.is_bought === '1') ? (
                                                        <button className="btn btn-sm orange_btn text-uppercase">Purchased</button>
                                                    ) : (
                                                        <button className="btn btn-sm orange_btn text-uppercase" onClick={() => this.props.history.push(`/tour-detail/${this.state.tour.tour_id}/purchase`)}>BUY NOW</button>
                                                    ) : null
                                            }

                                        </div>
                                    </div>
                                    <div className="price_box_footer">
                                        <p>Get a taste of what this has to offer</p>
                                        <div className="orange_link_text" style={{ cursor: 'pointer' }} onClick={this.playAudio}>
                                            {
                                                this.state.play ? this.state.loading ? (
                                                    <Fragment>
                                                        <img src={Spinner} style={{ height: '28px', width: '28px' }} />
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <img src={StopIcon} style={{ height: '17px', width: '17px' }} /> Stop
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <img src={musicImg} /> Hear a Short Snippet
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* price section ends here */}

                <section className="page_section">
                    <div className="container">
                        <div className="row tour_details_desc_data">
                            <div className="col-12 col-md-7">
                                <div className="">
                                    <h2 className=""><b>{this.state.tour.tour_name}</b></h2>
                                    <h5 className="">Duration: <b>{this.state.tour && this.state.tour.tour_total_time.split(':')[0] ? this.state.tour.tour_total_time.split(':')[0][0] === '0' ? this.state.tour.tour_total_time.split(':')[0][1] : this.state.tour.tour_total_time.split(':')[0] : ''}hr {this.state.tour && this.state.tour.tour_total_time.split(':')[1]}mins</b> | Distance: <b>{this.state.tour.tour_total_distance} km</b></h5>
                                    <p className=" mt_20"> {this.state.tour.tour_description} </p>
                                </div>
                            </div>
                            <div className="col-12 d-block d-sm-none">
                                <div className="price_box float-md-right">
                                    <div className="price_box_body">
                                        <h2 className="price_text">{(this.state.tour) ? (this.state.tour.tour_price === '0') ? 'Free' : '₹' + this.state.tour.tour_price : ''}</h2>
                                        <p>Starting Price per Adult</p>
                                        <div className="d-inline-block purchase_opt">
                                            <FontAwesomeIcon icon="heart" onClick={this.saveTour} className="like_option" style={this.state.saved ? { color: 'red', border: '1px solid silver' } : { color: 'grey', border: '1px solid silver' }}></FontAwesomeIcon>
                                            {
                                                (this.state.tour.tour_price === '0') ? (
                                                    <button className="btn btn-sm orange_btn text-uppercase" onClick={() => this.props.history.push(`/tour-detail/${this.state.tour.tour_id}/free`)}>Free</button>
                                                ) :
                                                    (this.state.tour) ? (this.state.tour.is_bought === '1') ? (
                                                        <button className="btn btn-sm orange_btn text-uppercase">Purchased</button>
                                                    ) : (
                                                        <button className="btn btn-sm orange_btn text-uppercase" onClick={() => this.props.history.push(`/tour-detail/${this.state.tour.tour_id}/purchase`)}>BUY NOW</button>
                                                    ) : null
                                            }

                                        </div>
                                    </div>
                                    <div className="price_box_footer">
                                        <p>Get a taste of what this has to offer</p>
                                        <div className="orange_link_text" style={{ cursor: 'pointer' }} onClick={this.playAudio}>
                                            {
                                                this.state.play ? this.state.loading ? (
                                                    <Fragment>
                                                        <img src={Spinner} style={{ height: '28px', width: '28px' }} />
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <img src={StopIcon} style={{ height: '17px', width: '17px' }} /> Stop
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <img src={musicImg} /> Hear a Short Snippet
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* // (this.state.play) ? (this.state.loading) ? Spinner : '' : musicImg    ' : 'Stop' : 'Hear a Short Snippet' */}

                {/* walks section starts here */}
                {
                    (this.state.tour.tour_type == 'Regular') ?
                        (
                            <section className="page_section mb-4 pt-0" >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <h2 className="main_title "><b>Destinations covered</b></h2>
                                            <p>The Points of Interest covered by the tour.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 map_section" style={{ height: '80vh', border: '0px solid black' }}>
                                            {
                                                (this.state.tourData && this.state.tourData.poi_on_walk && this.state.tour.tour_type == 'Regular') ? (
                                                    <MapComponent tourData={this.state.tourData} />
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        ) : null
                }
                {/* walks section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TourDetail);
