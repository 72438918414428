import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../../Common/components/header';
import FooterComponent from '../../../../Common/components/footer';
import SimpleAccordion from "../../../../Common/components/accordain";
import avatar1 from '../../../../../images/tour_list_banner.png';
import filterIcon from '../../../../../images/filter.png';
import { APIService } from '../../../../../Config/actions/apiAction';
import GridPage from './grid';
import { NavDropdown } from 'react-bootstrap';
import BuyNow from '../../../../Common/components/buynow';
import { TokenService } from '../../../../../Config/actions/tokenAction';
import swal from 'sweetalert';
import MetaDecorator from '../../../../Common/components/metaDecorator';

class TourList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listData: [],
            search: '',
            filterData: [],
            language: '',
            languages: [
                {
                    "language_id": "",
                    "language_nativename": "All"
                },
                {
                    "language_id": "1",
                    "tenant_id": "1",
                    "language_name": "English",
                    "language_code": "en",
                    "language_nativename": "English",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:22"
                },
                {
                    "language_id": "2",
                    "tenant_id": "1",
                    "language_name": "Hindi",
                    "language_code": "hi",
                    "language_nativename": "हिन्दी",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "3",
                    "tenant_id": "1",
                    "language_name": "Marathi",
                    "language_code": "mr",
                    "language_nativename": "मराठी",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "4",
                    "tenant_id": "1",
                    "language_name": "Gujarati",
                    "language_code": "gu",
                    "language_nativename": "ગુજરાતી",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "5",
                    "tenant_id": "1",
                    "language_name": "Japanese",
                    "language_code": "ja",
                    "language_nativename": "日本語 (にほんご／にっぽんご)",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "6",
                    "tenant_id": "1",
                    "language_name": "Russian",
                    "language_code": "ru",
                    "language_nativename": "русский язык",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "7",
                    "tenant_id": "1",
                    "language_name": "German",
                    "language_code": "de",
                    "language_nativename": "Deutsch",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "9",
                    "tenant_id": "1",
                    "language_name": "French",
                    "language_code": "fr",
                    "language_nativename": "français",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "10",
                    "tenant_id": "1",
                    "language_name": "Spanish",
                    "language_code": "es",
                    "language_nativename": "español",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "11",
                    "tenant_id": "1",
                    "language_name": "Arabic",
                    "language_code": "ar",
                    "language_nativename": "العربية",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                },
                {
                    "language_id": "12",
                    "tenant_id": "1",
                    "language_name": "Chinese",
                    "language_code": "zh",
                    "language_nativename": "中文 (Zhōngwén)",
                    "created_date": "0000-00-00 00:00:00",
                    "updated_date": "2019-09-06 08:25:29"
                }
            ],
            meta_info: {}
        }
    }

    componentDidMount() {
        this.props.dispatch(APIService('POST', '/tourlocation/alltourlist', '')).then((data) => {
            if (data && data.data.status === 'success') {
                this.setState({
                    listData: data.data.data.tours,
                    meta_info: (data.data.data.meta_info != null) ? data.data.data.meta_info : { title: 'HopOn India', meta_description: 'HopOn India', meta_keyword: 'HopOn India' },
                }, () => {
                    this.searchFilter();
                })
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
            else {
                console.log('error')
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    showToast = (type, message) => {
        swal({
            title: message,
            text: ' ',
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    searchHandler = (e) => {
        this.setState({
            ...this.state,
            search: e.currentTarget.value
        })
    }

    submitHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.searchFilter();
    }

    searchFilter = () => {
        if (this.state.search) {
            let data = [];
            this.state.listData.map((item, index) => {
                let tour_name = item.tour_name.toLowerCase();
                if (tour_name.includes(this.state.search)) {
                    data.push(item)
                }
            });
            this.languageFilter(data);
        }
        else {
            let data = this.state.listData;
            this.languageFilter(data);
        }
    }

    languageFilter = (data) => {
        if (this.state.language) {
            let list = data.filter((item, index) => {
                if (item.language_id === this.state.language) {
                    return item;
                }
            })

            this.setState({
                ...this.state,
                filterData: list
            })
        }
        else {
            this.setState({
                ...this.state,
                filterData: data
            })
        }
    }

    selectLanguage = (lang) => {
        this.setState({
            language: lang
        }, () => {
            this.searchFilter();
        })
    }

    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent />
                <BuyNow />
                {/* banner section starts here */}
                <div className="banner_section tour_list_banner">
                    <img src={avatar1} alt="Banner image" />
                </div>
                {/* banner section ends here */}

                {/* audio toure section starts here */}
                <section className="page_section serach_tours_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mt-3">
                                <h2 className="main_title"> Browse through all the tours <br />we have to offer!</h2>
                            </div>
                            <div className="col-12 text-center">
                                <ul className="search_section mt-3">
                                    <li className="inline_block">
                                        <form className="input-group" onSubmit={this.submitHandler}>
                                            <input type="text" className="form-control form-control-sm search_input_tour" value={this.state.search} onChange={this.searchHandler} placeholder="Which package are you looking for?" aria-label="Your email" aria-describedby="basic-addon2" />
                                            <div className="input-group-append">
                                                <button className="btn btn-sm btn-outline-white subscribe_btn my-0" type="submit">SEARCH</button>
                                            </div>
                                        </form>
                                    </li>
                                    {/* <li><img src={filterIcon} width="15" /></li> */}
                                    <li className="inline_block">

                                        <NavDropdown title="Language Filter" id="basic-nav-dropdown">
                                            {
                                                this.state.languages ? (
                                                    <Fragment>
                                                        {
                                                            this.state.languages.map((item, index) => {
                                                                return (
                                                                    <NavDropdown.Item onClick={() => this.selectLanguage(item.language_id)} style={(this.state.language === item.language_id) ? { backgroundColor: '#FF8548', color: 'white' } : {}} key={index}>{item.language_nativename}</NavDropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </Fragment>
                                                ) : null
                                            }

                                        </NavDropdown>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* audio toure section ends here */}

                {/* WALKS section starts here */}
                <div className="page_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center walks_section tour_list_data">
                                {/* <PackageGrid masonaryData={this.state.listData} allTourList={true} /> */}
                                <GridPage filterData={this.state.filterData} />
                                {/* <button className="btn btn-sm orange_btn text-uppercase load_more_btn">Load More</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* WALKS section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(TourList);