import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import tour1 from '../../../../images/city_detail_banner.png';
import BuyNow from '../../../Common/components/buynow';
import { APIService } from '../../../../Config/actions/apiAction';
import { TokenService } from '../../../../Config/actions/tokenAction';
import DefaultImg from '../../../../images/default_large.png';
import swal from 'sweetalert';
import MetaDecorator from '../../../Common/components/metaDecorator';

class buyAllTours extends Component {

    constructor(props) {
        super(props);
        this.state = {
            unsortedData: [],
            listData: [],
            expanded: 0,
            promocode: {},
            meta_info: {
                title: 'Buy Tours',
                meta_description: 'Buy Tours - HopOn India',
                meta_keyword: 'Buy Tours - HopOn India'
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(APIService('POST', '/home/landing', '')).then((data) => {
            if (data.data.status === 'success') {
                this.setState({
                    unsortedData: data.data.data
                }, () => {
                    let data = Object.values(this.state.unsortedData)
                    let sortedCity = data.sort(this.compareCity);
                    sortedCity.map((item) => {
                        item.details.sort(this.compareTour);
                    });
                    sortedCity.forEach((city, index) => {
                        if (index == 0) { city.expanded = true }
                        else { city.expanded = false }
                    });
                    this.setState({
                        listData: sortedCity
                    })
                })
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
        }).catch(err => {
            console.error(err);
        })
    }

    // Accordion expanding functionality
    handleChange = (index) => {
        // this.setState({
        //     expanded: index
        // })
        let newArray = [...this.state.listData];
        newArray[index] = { ...newArray[index], expanded: !newArray[index].expanded }
        this.setState({ listData: newArray });
    }

    // Show toast notification
    showToast = (type, message) => {
        swal({
            title: message,
            text: ' ',
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    // Handling promocode
    promocodeHandler = (key, value) => {
        let promocode = this.state.promocode;
        promocode[key] = value;
        this.setState({ promocode }, () => {
        });
    }

    // Route to the particular tour or package detail page
    route = (item) => {
        let url = '';
        if (item.type === 'tour') {
            url = `/tour-detail/${item.id}`;
        }
        else {
            url = `/package-detail/${item.id}`;
        }
        this.props.history.push(url);
    }

    purchase = (e, tour) => {
        e.preventDefault();
        e.stopPropagation();

        let promocodeString = tour.type === 'tour' ? `tour_${tour.id}` : `package_${tour.id}`;
        let query = this.state.promocode[promocodeString] ? `?promocode=${this.state.promocode[promocodeString]}` : '';
        if (tour.type === 'tour') {
            this.props.history.push({ pathname: `/tour-detail/${tour.id}/purchase`, search: query });
        }
        else if (tour.type === 'package') {
            this.props.history.push({ pathname: `/package-detail/${tour.id}/purchase`, search: query });
        }
    }

    // Sorting of the array list
    compareCity(a, b) {
        if (a.city_name < b.city_name) {
            return -1;
        }
        if (a.city_name > b.city_name) {
            return 1;
        }
        return 0;
    }

    compareTour(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    render() {
        return (
            <Fragment>
                <MetaDecorator meta_info={this.state.meta_info} />
                {/* Html goes here */}
                <HeaderComponent />
                <BuyNow />
                {/* banner section starts here */}
                {/*<div className="banner_section inner_banner faq_banner"> 
                     <img src={avatar1} alt="Banner image" />
                    <div className="shadow_div_set"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center banner_title">
                                <h1 className=" white_text">FAQS</h1>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* banner section ends here */}

                {/* content section starts here */}
                <section className="page_section faq_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="acc_tours_buy">
                                    {
                                        this.state.listData && this.state.listData.length > 0 ? (
                                            <Fragment>
                                                {
                                                    this.state.listData.map((item, index) => {
                                                        return (
                                                            //expanded={this.state.expanded === index}
                                                            <Accordion key={index} expanded={item.expanded} onChange={() => this.handleChange(index)}>
                                                                <AccordionSummary aria-controls={index} id={index} >
                                                                    <h4 className="accordian_package_title">{item.city_name}
                                                                        {
                                                                            (item.expanded) ? (
                                                                                <FontAwesomeIcon icon="angle-up" className="arrow_icon" />
                                                                            ) : (
                                                                                <FontAwesomeIcon icon="angle-down" className="arrow_icon" />
                                                                            )
                                                                        }
                                                                    </h4>
                                                                </AccordionSummary>

                                                                {
                                                                    item && item.details.length > 0 ? (
                                                                        <Fragment>
                                                                            {
                                                                                item.details.map((tour, i) => {
                                                                                    return (
                                                                                        <AccordionDetails key={i}>
                                                                                            <div className="tour_details_show">
                                                                                                <div className="left_side_show">
                                                                                                    <img src={tour.image || DefaultImg} alt="Tour Image" width="100%" height="100%" className="toue_image" />
                                                                                                    <h4>{tour.name}</h4>
                                                                                                    {
                                                                                                        tour.type === 'tour' ? (
                                                                                                            <p className="orange_text"><span style={{ textTransform: 'uppercase' }}>{tour.type}</span> | {tour.total_points} points | {tour.tour_total_distance} km</p>
                                                                                                        ) : (
                                                                                                            <p className="orange_text"><span style={{ textTransform: 'uppercase' }}>{tour.type}</span></p>
                                                                                                        )
                                                                                                    }
                                                                                                    <p>{tour.description && tour.description.length > 100 ? tour.description.slice(0, 100) : tour.description}... <span className="green_text" onClick={() => this.route(tour)}>Read More</span></p>
                                                                                                </div>
                                                                                                <div className="purchase_right_side">
                                                                                                    <h4><b>{tour.price === '0' ? 'Free Tour' : `Rs.${tour.price}`}</b></h4>
                                                                                                    <form className="tour_purchase_promocode" onSubmit={(e) => this.purchase(e, tour)} >
                                                                                                        {
                                                                                                            tour.price !== '0' ? (
                                                                                                                <div className="form-group">
                                                                                                                    <input type="text" id="city-promocode" value={this.state.promocode[tour.type + '_' + tour.id] || ''} onChange={(e) => this.promocodeHandler(tour.type + '_' + tour.id, e.currentTarget.value)} className="form-control" name="City[promocode]" placeholder="Enter Promocode" />
                                                                                                                    <input type="submit" className="btn orange_btn btn-sm text-uppercase" value="Buy Now" />
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <div className="form-group">
                                                                                                                    <input type="text" style={{ opacity: '0' }} id="city-promocode" className="form-control" name="City[promocode]" placeholder="Enter Promocode" />
                                                                                                                    <input type="submit" style={{ opacity: '0' }} className="btn orange_btn btn-sm text-uppercase" value="Buy Now" />
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </AccordionDetails>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                    ) : null
                                                                }
                                                            </Accordion>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        ) : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* faqs section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(buyAllTours);