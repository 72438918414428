import { Fragment } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { APIService } from "../../../../Config/actions/apiAction";
import Recaptcha from 'react-recaptcha';
import swal from 'sweetalert';
import { TokenService } from "../../../../Config/actions/tokenAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {countryCodes} from '../../../../utitls/index';

let recaptchaInstance;

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVerified: false,
            email: '',
            name: '',
            subject: 'Get In Touch',
            countryCode: countryCodes[94].code,
            number:'',
            message: '',
            error: {
                email: '',
                name: '',
                subject: '',
                message: ''
            }
        }
    }

  
    componentDidMount() {
        if (this.props.match.params && this.props.match.params.subject) {
            let str = this.props.match.params.subject;
            let subject;
            if (str == 'get-in-touch') { subject = 'Get In Touch' }
            if (str == 'request-a-demo') { subject = 'Request A Demo' }
            if (str == 'i-need-ustomization') { subject = 'I Need Customization' }
            // let subject = str.split('-').join(' ');
            this.setState({
                ...this.state,
                subject: subject
            })
        }
    }

    onCaptchaLoaded = () => {
        // console.log('Google Recaptcha Loaded');
    }

    // When user clicks on recaptcha to set it
    setRecaptcha = (response) => {
        if (response) {
            this.setState({
                isVerified: true
            })
        }
    }

    // Popup taost notification
    showToast = (type, message) => {
        swal({
            title: message,
            text: ' ',
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    // Reseting the recaptcha
    resetRecaptcha = (e) => {
        e.preventDefault();
        this.setState({
            isVerified: false
        }, () => {
            recaptchaInstance.reset();
        })
    }

    // Handling the input values
    inputHandler = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    // Submit the form
    submitHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (this.state.email && this.state.name && this.state.message && this.state.subject && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email))) {
            this.setState({
                ...this.state,
                error: {
                    email: '',
                    name: '',
                    subject: '',
                    message: '',
                    number:'',
                }
            }, () => {
                let fd = new FormData();
                fd.append('name', this.state.name);
                fd.append('email', this.state.email);
                fd.append('subject', this.state.subject);
                fd.append('body', this.state.message);
                let phoneNumber = this.state.countryCode;
               const numberValue = `+${phoneNumber}${this.state.number}`;
               fd.append('number', numberValue);
                
                this.props.dispatch(APIService('POST', '/contactus/contact-us', fd)).then((response) => {
                    if (response && response.data.status === 'success') {
                        this.setState({
                            name: '',
                            email: '',
                            subject: '',
                            message: '',
                            number:'',
                        }, () => {
                            this.resetRecaptcha(event);
                            this.showToast('success', response.data.message)
                        })
                    }
                    else if (response.data.auth_failed === '1') {
                        this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                            this.showToast('error', 'Invalid login. Please try again');
                            this.props.logoutUser();
                        })
                    }
                    else {
                        this.resetRecaptcha(event);
                    }
                }).catch((e) => {
                    // console.log(e)
                })
            })
        }
        else {
            this.setState({
                ...this.state,
                error: {
                    name: this.state.name ? '' : 'Name is required',
                    email: this.state.email ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email) ? '' : 'Enter a valid email address' : 'Email address is required',
                    subject: this.state.subject ? '' : 'Subject is required',
                    message: this.state.message ? '' : 'Message is required'
                }
            }, () => {
                this.resetRecaptcha(event)
            })
        }
    }

    render() {
        return (
            <Fragment>
                {/* contact form starts here */}
                <section className="page_section contact_abt_section mt-3 mt-md-5" id="contactForm">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-left text-md-center mb_30 mt-md-1 mt-4 ">
                                <h2 className="main_title white_text">Have something <br />to share?</h2>
                                <p className="white_text">Feedback from some Tours? An idea for a tour? Want to just reach out <br />and say hi? Feel free to drop us a line!</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="page_section page_section mb-2 mb-md-5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-12 col-md-10">
                                <div className="conatct_box">
                                    <form onSubmit={this.submitHandler}>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                    </div>
                                                    <input type="text" className="form-control" value={this.state.name} onChange={(e) => this.inputHandler('name', e.currentTarget.value)} placeholder="Name" />
                                                </div>
                                                {this.state.error.name ? (
                                                    <p style={{ color: 'red', fontSize: '13px', marginLeft: '5px', marginTop: '-8px' }}>{this.state.error.name}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><FontAwesomeIcon icon="envelope" /></span>
                                                    </div>
                                                    <input type="text" className="form-control" value={this.state.email} onChange={(e) => this.inputHandler('email', e.currentTarget.value)} placeholder="Email" />
                                                </div>
                                                {this.state.error.email ? (
                                                    <p style={{ color: 'red', fontSize: '13px', marginLeft: '5px', marginTop: '-8px' }}>{this.state.error.email}</p>
                                                ) : null}
                                            </div>
                                        
                                           <div className="col-12">
                                           <div className="input-group mb-3">
                                                <div className="input-group-prepend" id="button-addon3">
                                                    <div className="btn btn-outline-secondary" style={{border:'none'}}><FontAwesomeIcon icon='mobile'/></div>
                                                    <select className="form-control" style={{width:'100px'}} value={this.state.countryCode} onChange={(e) => this.inputHandler('countryCode', e.currentTarget.value)}>
                                                       {countryCodes?.map(({code, iso}) =>(
                                                        <option key={iso} value={code}>{`+${code} ${iso}`}</option>
                                                       ))}
                                                    </select>
                                                </div>
                                                <input type="text" class="form-control" value={this.state.number} placeholder="Enter Your Phone Number" onChange={(e) => this.inputHandler('number', e.currentTarget.value)}/>
                                            </div>
                                            {this.state.error.number ? (
                                                    <p style={{ color: 'red', fontSize: '13px', marginLeft: '5px', marginTop: '-8px' }}>{this.state.error.number}</p>
                                                ) : null}
                                           </div>
                                            
                                            <div className="col-12">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><FontAwesomeIcon icon="pen" /></span>
                                                    </div>
                                                    <select className="form-control" value={this.state.subject} onChange={(e) => this.inputHandler('subject', e.currentTarget.value)}>
                                                        <option value="Get In Touch">Get In Touch</option>
                                                        <option value="I Need Customization">I Need Customization</option>
                                                        <option value="Request A Demo">Request A Demo</option>
                                                    </select>
                                                    {/* <input type="text" className="form-control" value={this.state.subject} onChange={(e) => this.inputHandler('subject', e.currentTarget.value)} placeholder="Subject" /> */}
                                                </div>
                                                {this.state.error.subject ? (
                                                    <p style={{ color: 'red', fontSize: '13px', marginLeft: '5px', marginTop: '-8px' }}>{this.state.error.subject}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <textarea rows="2" className="form-control" style={{ border: '1px solid silver' }} value={this.state.message} onChange={(e) => this.inputHandler('message', e.currentTarget.value)} placeholder="Your Message in details" />
                                                </div>
                                                {this.state.error.message ? (
                                                    <p style={{ color: 'red', fontSize: '13px', marginLeft: '5px', marginTop: '-8px' }}>{this.state.error.message}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-12 col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <Recaptcha
                                                        sitekey="6LfLAHsbAAAAAJ82RJa3f4qrsnAF7cZUPhEFlGBc"
                                                        render="explicit"
                                                        verifyCallback={this.setRecaptcha}
                                                        onloadCallback={this.onCaptchaLoaded}
                                                        ref={e => recaptchaInstance = e}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <input type="submit" className="btn btn-sm orange_btn" disabled={!this.state.isVerified} value="SEND" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Contact));
