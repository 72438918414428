import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import SimpleAccordion from "../../../Common/components/accordain";
import avatar1 from '../../../../images/home_banner.png';
import bgImg from '../../../../images/bg_img_home.svg';
import OwlCarousel, { render } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PackageGrid from '../../../Common/components/masnoryHome';
import appImg from '../../../../images/mobile_app.png';
import PhoneCase from '../../../../images/phone_case.png';
import PhoneImg from '../../../../images/phn_img.png';
import work1 from '../../../../images/work_1.png';
import DefaultImg from '../../../../images/default_large.png';
import work2 from '../../../../images/work_2.png';
import work3 from '../../../../images/work_3.png';
import { Howl, Howler } from 'howler';
import LoadingImg from '../../../../images/loading.gif';
import { TokenService } from '../../../../Config/actions/tokenAction';
import { APIService } from '../../../../Config/actions/apiAction';
import { ToastContainer, toast } from 'react-toastify';
import BuyNow from '../../../Common/components/buynow';
import Skeleton from '@material-ui/lab/Skeleton';
import swal from 'sweetalert';
import Recaptcha from 'react-recaptcha';
import Contact from './contact';
import Typed from 'react-typed';
import GPlay from '../../../../images/gplay.png';
import Applestore from '../../../../images/applestore.png';
import MetaDecorator from '../../../Common/components/metaDecorator';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tourGuide: [],
            activeTrack: '',
            playing: false,
            player: '',
            loading: false,
            inProgress: false,
            homeData: {},
            messagesEnd: null,
            headerStrings: ["the story of Humayuns Tomb", "chandni chowk food tours", "national museum music gallery"],
            owlStartPosition: 0,
            meta_info: {},
        }
    }

    componentDidMount() {
        this.props.dispatch(APIService('POST', '/home/details', '')).then((res) => {
            if (res && res.data.status === 'success') {
                let bannerData = [];
                res.data.data.banners.forEach(element => {
                    bannerData.push(element.tour_name);
                });
                this.setState({
                    homeData: res.data.data,
                    headerStrings: bannerData,
                    meta_info: (res.data.data.meta_info != null) ? res.data.data.meta_info : { title: 'HopOn India', meta_description: 'HopOn India', meta_keyword: 'HopOn India' }
                }, () => {
                    this.scrollPosition();
                })
            }
            else if (res.data.auth_failed === '1') {
                this.scrollPosition();
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showPopupToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
        }).catch((e) => {
            // console.log(e)
        })
        this.setState({
            tourGuide: this.tourGuide
        }, () => {
            // console.log(this.state)
        })
    }

    // Scroll the page to specific point on the webpage
    scrollPosition = () => {
        if (!this.props.match.params.subject) {
            if (sessionStorage.getItem('homeScroll')) {
                let value = sessionStorage.getItem('homeScroll');
                window.scrollTo(0, value);
                sessionStorage.removeItem('homeScroll');
            }
            // console.log(this.props)
            else if (this.props.match.params && this.props.match.params.subject) {
                this.messagesEnd.scrollIntoView({ behavior: "smooth" });
            }
        }
        else {
            // window.scrollTo(0, 6649);
        }
    }

    responsive = {
        0: {
            items: 1,
        },
        320: {
            items: 2,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,
        }
    };

    // Play / Pause audio
    playAudio = (e, track, owlStartPosition) => {
        if (this.state.inProgress == false) {
            this.setState({
                inProgress: true,
            }, () => {
                if (this.state.activeTrack === track) {
                    // Resume
                    this.setState({
                        playing: true
                    }, () => {
                        this.state.player.play();
                        this.forceUpdate();
                    })
                }
                else {
                    if (this.state.player) {
                        this.state.player.stop();
                    }
                    let self = this;
                    this.setState({
                        loading: true
                    }, () => {
                        this.forceUpdate();
                        const player = new Howl({
                            src: [track],
                            autoplay: true,
                            onload: () => {
                                self.setState({
                                    loading: false,
                                    activeTrack: track,
                                    playing: true,
                                    player: player,
                                    owlStartPosition: owlStartPosition,
                                    inProgress: false
                                }, () => {
                                    this.forceUpdate();
                                });
                            },
                            onloaderror: () => {
                                self.setState({
                                    loading: false,
                                    playing: false
                                }, () => {
                                    this.showToast('error', 'Error fetching audio. Please contact administrator')
                                    this.forceUpdate();
                                });
                                // this.state.show
                            },
                            onend: () => {
                                this.setState({
                                    playing: false,
                                    loading: false,
                                    player: '',
                                    activeTrack: ''
                                })
                            }
                        })
                        // .play();
                        // this.setState({
                        //     activeTrack: track,
                        //     playing: true,
                        //     player: player
                        // }, () => {
                        //     this.forceUpdate();
                        // });
                    })
                }
            })
        }
        // console.log(this.state,"state");

    }

    // Show swal alert
    showPopupToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    // Show toast notification
    showToast = (type, message) => {
        if (type === 'success') {
            toast.success(message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false
            })
        }
        else {
            toast.error(message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false
            })
        }
    }

    // Pause any playing audio
    pauseAudio = (e) => {
        this.setState({
            playing: false
        }, () => {
            this.state.player.pause();
            this.forceUpdate();
        })
    }

    route = (url) => {
        sessionStorage.setItem('homeScroll', window.pageYOffset);
        this.props.history.push(url)
    }

    componentWillUnmount() {
        if (this.state.player) {
            this.state.player.stop();
        }
    }
    render() {
        return (
            <Fragment>
                {/* Html goes here */}
                <MetaDecorator meta_info={this.state.meta_info} />
                <HeaderComponent active={'hopon'} />
                <BuyNow />
                {/* banner section starts here */}
                <ToastContainer />
                <div className="banner_section main_home_banner">
                    {/* <img src={avatar1} alt="Banner image" /> */}
                </div>
                <section className="banner-title-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <h2>
                                    Explore<br /><span className="bottm_dash_border"><Typed strings={this.state.headerStrings} backSpeed={40} typeSpeed={60} loop={true} /></span><br /> with a tour guide in your ear, <br />From the comfort of your home
                                </h2>
                                <div className="download_opt_btn">
                                    <a href="https://play.google.com/store/apps/details?id=com.hoponindia&hl=en_IN&gl=US">
                                        <img src={GPlay} width="80" />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/hopon-india/id1205385874">
                                        <img src={Applestore} width="80" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* banner section ends here */}

                {/* audio toure section starts here */}
                <section className="page_section audiio_tour_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-6 d-none d-md-block">
                                <div className="image_section_set">
                                    <img width={300} src={appImg} alt="" className="phone_img" />
                                    {/* <img width={350} src={PhoneCase} alt="" className="phone_img"/>
                                    <div className="set_image_box">
                                        <img width={227} src={PhoneImg} alt="" className="set_img"/>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <h2 className="main_title">Why Audio Tours?</h2>
                                <ul className="list_why list_of_tours">
                                    <li>
                                        <FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon>
                                        <h4>Control is yours</h4>
                                        Tours at your own pace, not herded in a huge group or packed up in a bus. Take your time to soak in the experience of the stories around you coming to life.
                                    </li>
                                    <li>
                                        <FontAwesomeIcon className="list_why_icon" icon="check"></FontAwesomeIcon>
                                        <h4>Expertly crafted experiences </h4>
                                        Explore while listening to expertly narrated commentary & guidance, with sound effects and music to completely immerse you. Allow our local guides to take you for a spin.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* audio toure section ends here */}

                {/* Work section ends here */}
                <section className="page_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="main_title">How Does it work?</h2>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12  col-md-8 order-2 order-md-1">
                                <div className="set_div_center">
                                    <h4 className="">
                                        Decide where to go
                                    </h4>
                                    <p>
                                        Pick your tourist destination or chose from our city based packages. If the tour isn’t one of our free ones, proceed to buy the tour from the HopOn India app or website.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 text-center order-md-2 order-1 text-md-right">
                                <img width={300} src={work1} alt="" className="work_img" />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-4 text-center text-md-left">
                                <img width={350} src={work2} alt="" className="work_img" />
                            </div>
                            <div className="col-12 col-md-8 text-rleft text-md-right">
                                <div className="set_div_center">
                                    <h4 className="">
                                        Pop on your earphones
                                    </h4>
                                    <p>
                                        Get ready for an experience like no other! Open the HopOn India PWA or download the app on your phone, and get ready with your earphones on standby!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 order-md-1 order-2">
                                <div className="set_div_center">
                                    <h4 className="">
                                        Discover new experiences
                                    </h4>
                                    <p>
                                        Regardless of if you’re taking the tour while being physically present in the location or virtually while being elsewhere, lose yourself in the immersive stories that our tours narrate.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 text-center text-md-right order-md-2 order-1">
                                <img width={350} src={work3} alt="" className="work_img" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page_section tour_guide_section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4 col-sm-6 mb_30 ">
                                <h2 className="main_title white_text">An App to Replace <br />your tour guide!</h2>
                                <p className="white_text">Don’t take our word for it! Get a small taste of our audio tours and decide for yourselves.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center tour_section_slider mb_30">
                                {
                                    (this.state.homeData.banners && this.state.homeData.banners.length > 0) ? (
                                        <Fragment>
                                            {/* loop={this.state.homeData.banners.length > 4 ? true : false} */}
                                            <OwlCarousel className="owl-theme" startPosition={this.state.owlStartPosition} margin={20} nav dots navClass={'owl-prev', 'owl-next'} responsive={this.responsive} >
                                                {
                                                    this.state.homeData.banners.map((item, index) => {
                                                        return (
                                                            <div className="item" key={index}>
                                                                <img src={item.banner_img} alt="Tour Image" style={{ height: '100%' }} />
                                                                <div className="img_text">

                                                                    {
                                                                        (this.state.activeTrack === item.intro_audio && this.state.playing === true && this.state.loading === false) ? (
                                                                            <Fragment>
                                                                                <div className="icon_set_volumn" style={{ cursor: 'pointer' }} onClick={this.pauseAudio}>
                                                                                    <FontAwesomeIcon icon="pause"></FontAwesomeIcon>
                                                                                </div>
                                                                            </Fragment>
                                                                        ) : (this.state.activeTrack === item.intro_audio && this.state.playing === true && this.state.loading === true) ? (
                                                                            <Fragment>
                                                                                <div style={{ cursor: 'pointer', backgroundColor: 'black', padding: '7px', borderRadius: '50px', border: '2px solid white' }}>
                                                                                    <img src={LoadingImg} class="audio_loader" style={{ backgroundColor: 'none', width: '28px' }} />
                                                                                    {/* <FontAwesomeIcon icon="volume-up"></FontAwesomeIcon> */}
                                                                                </div>
                                                                            </Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <div className="icon_set_volumn" style={{ cursor: 'pointer' }} onClick={(e) => this.playAudio(e, item.intro_audio, index)}>
                                                                                    <FontAwesomeIcon icon="volume-up"></FontAwesomeIcon>
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                </div>
                                                                <p className="white_text">{item.title}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </OwlCarousel>
                                        </Fragment>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {/* tour guide section ends here */}

                {/* cities section starts here */}
                <section className="page_section" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-4 mt-4 mt-md-0">
                                <h2 className="main_title">Cities</h2>
                                <p>Browse our city-wise tour and package offerings</p>
                            </div>
                        </div>
                        <div className="row">
                            {
                                (this.state.homeData.cities && this.state.homeData.cities.length) ? (
                                    <Fragment>
                                        {
                                            this.state.homeData.cities.map((item, index) => {
                                                return (
                                                    <div key={index} className="col-6 col-sm-4 col-md-3 mb_30" style={{ cursor: 'pointer' }} onClick={() => this.route('/city-list/' + item.city_name.toLowerCase())}>
                                                        <div className="city_img_bind">
                                                            <div className="city_title">
                                                                {item.city_name}
                                                            </div>
                                                            <img className="city_img" src={item.city_photo} alt="" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Fragment>
                                ) : null
                            }
                        </div>
                    </div>
                </section>
                {/* cities section ends here */}

                {/* package section starts here */}
                <div className="banner_section package_section">
                    <img src={bgImg} alt="Banner image" />
                    <h2 className="banner_title">
                        Value for Money <br />Packages
                    </h2>
                    <p className="sub_title_banner">A collection of tours hand crafted and selected to give you a holistic <br />experience per package.</p>
                </div>
                <div className="page_section package_slider_section">
                    <div className="container">
                        <div className="row package_slider_desktop">
                            {/* package slider starts */}
                            <div className="col-1"></div>
                            <div className="col-10 text-center package_section_slider">
                                {
                                    (this.state.homeData && this.state.homeData.package_info) ? (
                                        <OwlCarousel className="owl-theme" loop margin={20} nav dots navclass={'owl-prev', 'owl-next'} items={1}>
                                            {
                                                this.state.homeData.package_info.map((item, index) => {
                                                    return (
                                                        <div className="item" key={index}>
                                                            <div className="package_box">
                                                                <div className="pacakge_left_img">
                                                                    <img src={item.banner_img} alt="Tour Image" />
                                                                </div>
                                                                <div className="package_right_detail">
                                                                    <h4 className="text-uppercase">{item.package_name}
                                                                        <small className="walk_text d-none text-right"> 17 Tours </small></h4>
                                                                    <p>An anoucement of the best that Mumbai has to offer, from a Tour among the Bollywood Stars, the Gateway Of India and the colorful Dadar Parsi Colony. Get a taste of the multituders of the city near sleeps. </p>
                                                                    <ul className="package_list_imgs mb_30">
                                                                        {
                                                                            (item.walks && item.walks.length) ? item.walks.map((walk, i) => {
                                                                                if (i < 7) {
                                                                                    return (
                                                                                        <li key={i}>
                                                                                            <p> <img src={walk.banner_img || DefaultImg} alt="Tour Image" />  </p>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            }) : ''
                                                                        }
                                                                        {
                                                                            item.walks && item.walks.length > 8 ? (
                                                                                <li className="more_package_imgs">
                                                                                    <p>{item.walks.length - 7}+</p>
                                                                                </li>
                                                                            ) : null
                                                                        }
                                                                    </ul>
                                                                    <div className="text-center text-md-right btn_section_explore">
                                                                        <button className="btn btn-sm orange_btn" onClick={() => this.route('/package-detail/' + item.package_id)}>Explore <FontAwesomeIcon icon="angle-right" /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </OwlCarousel>
                                    ) : null
                                }


                            </div>
                            <div className="col-1"></div>
                            {/* package slider ends */}
                        </div>
                        {/* responsive starts here */}
                        <div className="row responsive_package_section d-none">
                            {
                                (this.state.homeData && this.state.homeData.package_info) ? (
                                    <SimpleAccordion package_info={this.state.homeData.package_info} />
                                ) : null
                            }
                        </div>
                        {/* responsive ends here   */}
                    </div>
                </div>
                {/* package section ends here */}

                {/* WALKS section starts here */}
                <div className="page_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mb_30">
                                <h2 className="main_title text-center  mb-1">All Tours</h2>
                                <p className="text-center mb-4 mb-md-5">Explore all the tours that HopOn India has to offer!</p>
                                <button onClick={() => this.route('/tour-list')} className="btn btn-sm orange_btn mb-3 mb-md-5">Explore  <FontAwesomeIcon icon="angle-right" /></button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center walks_section">
                                <PackageGrid route={this.route} masonaryData={this.state.homeData.walk_info} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* WALKS section ends here */}

                <div style={{ float: "left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
                {/* contact form section starts here */}
                <Contact {...this.props} />
                {/* contact form section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
