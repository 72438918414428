import React, { useEffect, Fragment } from 'react';
import logo from "../../../images/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavDropdown } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { connect } from 'react-redux';
import { TokenService } from '../../../Config/actions/tokenAction';
// import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert';

function HeaderComponent(props) {

    const navigate = (route) => {
        sessionStorage.removeItem('homeScroll');
        props.history.push(route);
    }

    const replace = (url) => {
        window.open(url);
    }

    const logout = () => {
        props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
            props.logoutUser();
            showToast('success', 'Logout Successful')
        })
    }

    const showToast = (type, message) => {
        swal({
            text: ' ',
            title: message,
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    useEffect(() => {
        const header = document.getElementById("myHeader");
        const sticky = header.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky) {
                header.classList.add("header_sticky");
            } else {
                header.classList.remove("header_sticky");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);

    return (
        <Fragment>
            <div className="header_section" id="myHeader">
                <div className="container">
                    {/* <ToastContainer /> */}
                    <div className="row">
                        <Navbar bg="light" expand="lg">
                            <Navbar.Brand href="/cities-and-tours"><img width={120} src={logo} alt="Logo" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                    <Nav.Link style={props.active === 'hopon' ? { fontWeight: 'bold' } : {}} onClick={() => navigate("/cities-and-tours")} >Tours</Nav.Link>
                                    <Nav.Link style={props.active === 'home' ? { fontWeight: 'bold' } : {}} onClick={() => navigate("/")} >Partner</Nav.Link>
                                    <Nav.Link onClick={() => replace("https://www.hoponindia.com/blog/")} >Blog</Nav.Link>
                                    <Nav.Link style={props.active === 'about' ? { fontWeight: 'bold' } : {}} onClick={() => navigate("/about-us")} >About Us</Nav.Link>
                                    {
                                        props.user.isAuthenticated ? (
                                            <NavDropdown title="My profile" id="basic-nav-dropdown">
                                                <NavDropdown.Item onClick={() => navigate("/my-profile")}>Profile</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => navigate("/my-tour")}>My Tours</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
                                            </NavDropdown>
                                        ) : (
                                            <Nav.Link style={props.active === 'login' ? { fontWeight: 'bold' } : {}} onClick={() => navigate("/login")} >Login</Nav.Link>
                                        )
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderComponent));